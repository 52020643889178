import {MediaMatcher} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AventiObject} from 'src/app/models/AventiObject';
import {Checklist} from 'src/app/models/Checklist';
import {AventiFile} from 'src/app/models/File';
import {Objecttype} from 'src/app/models/Objecttype';
import {User} from 'src/app/models/User';
import {CurrentpathService} from 'src/app/services/currentpath.service';
import {DateService} from 'src/app/services/date.service';
import {IdService} from 'src/app/services/id.service';
import {ObjectService} from 'src/app/services/object.service';
import {PopupService} from 'src/app/services/popup.service';
import {SharedService} from 'src/app/services/shared.service';
import {SnackbarService} from 'src/app/services/snackbar.service';
import {environment} from 'src/environments/environment';
import {ConfirmComponent} from '../../common/bottom-sheet/confirm/confirm.component';
import {Project} from 'src/app/models/Project';

@Component({
  selector: 'app-object',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.css'],
  providers: [ObjectService],
})
export class ObjectComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  Form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });
  FormDesc = new UntypedFormGroup({
    desc: new UntypedFormControl('', []),
  });
  FormProfilenumber = new UntypedFormGroup({
    profilenumber: new UntypedFormControl('', []),
  });
  FormProcess = new UntypedFormGroup({
    process: new UntypedFormControl('', []),
  });
  FormDrawing = new UntypedFormGroup({
    drawing: new UntypedFormControl('', []),
  });
  FormComment = new UntypedFormGroup({
    comment: new UntypedFormControl('', []),
  });
  FormTags = new UntypedFormGroup({
    key: new UntypedFormControl('', []),
    value: new UntypedFormControl('', []),
  });
  error: string;

  get name() {
    return this.Form.get('name');
  }
  get desc() {
    return this.FormDesc.get('desc');
  }
  get profilenumber() {
    return this.FormProfilenumber.get('profilenumber');
  }
  get process() {
    return this.FormProcess.get('process');
  }
  get drawing() {
    return this.FormDrawing.get('drawing');
  }
  get comment() {
    return this.FormComment.get('comment');
  }
  get key() {
    return this.FormTags.get('key');
  }
  get value() {
    return this.FormTags.get('value');
  }

  object: AventiObject;
  project: Project;
  projectid: string;
  objectid: string;
  isLoading = true;
  isAdmin = false;
  objecttypes: Objecttype[];
  editObjecttype = false;
  editname = false;
  editdesc = false;
  editprofilenumber = false;
  editprocess = false;
  editdrawing = false;
  editcomment = false;
  edittags: boolean[] = [];
  receiptforms: Checklist[] = [];
  receiptformsTemp: Checklist[];
  checklists: Checklist[] = [];
  checklistpdfs: AventiFile[];
  filesLoaded: boolean;
  checklistsSigned = false;

  files: AventiFile[];

  user: User;

  datetime: string;

  mobileQuery: MediaQueryList;

  constructor(
    private idservice: IdService,
    private objectservice: ObjectService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    media: MediaMatcher,
    private snackbarservice: SnackbarService,
    private dateservice: DateService,
    private sharedservice: SharedService,
    private popupservice: PopupService,
    private currentpathservice: CurrentpathService
  ) {
    this.mobileQuery = media.matchMedia(environment.mobileQueryMaxwidthSmall);
    sharedservice.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      if (user !== null) {
        this.user = user[0];
        this.isAdmin = this.user.role?.name === 'admin';
      }
    });
    this.projectid = this.idservice.projectId();
    this.objectid = this.idservice.objectId();
    this.sharedservice.objecttypes
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((types) => {
        this.objecttypes = types.filter(type => type.category?.id !== 'loJgvvKxjzx1jk771Wuf'); // Filter out Mottak category
      });
  }

  ngOnInit(): void {
    this.sharedservice.project
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((project) => {
        if (project !== null) {
          this.project = project;
        }
      });
    this.sharedservice.objects
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((objects) => {
        if (objects !== null) {
          objects.forEach((object) => {
            if (object.id === this.objectid) {
              this.object = object;
              this.buildObject();
            }
          });
        }
      });

    this.sharedservice.receiptforms
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((receiptforms) => {
        if (receiptforms !== null) {
          this.receiptformsTemp = receiptforms;
          this.buildObject();
        }
      });

    this.objectservice
      .getObjectFiles(this.objectid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((files) => {
        if (files !== null) {
          this.files = files;
        }
      });

    // this.sharedservice.checklists
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((checklists: Checklist[]) => {
    //     // if (checklists.length > 0) {
    //     this.checklistsTemp = checklists;
    //     this.buildObject();
    //     // }
    //   });

    // this.sharedservice.checklistpdfs
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((cpdfs) => {
    //     // if (cpdfs.length > 0) {
    //     this.checklistpdfs = cpdfs;
    //     this.buildObject();
    //     // }
    //   });
  }

  buildObject() {
    if (
      this.object &&
      // this.checklistsTemp &&
      this.receiptformsTemp
      // this.checklistpdfs
    ) {
      if (this.object.checklists?.length > 0) {
        this.object.checklists.forEach((cl) => {
          if (cl.status === 'signed') {
            this.checklistsSigned = true;
          }
        });
      }
      this.object.path =
        'projects/' + this.idservice.projectId() + '/objects/' + this.object.id;

      if (this.object.tags?.length > 0 && this.edittags?.length === 0) {
        this.object.tags.forEach((_, index) => {
          this.edittags[index] = false;
        });
      }
      if (!this.object.lastupdated && this.object.editedBy) {
        this.object.lastupdated =
          this.object.editedBy[this.object.editedBy.length - 1].timestamp;
      }
      if (this.object.lastupdated) {
        this.datetime = this.dateservice.dateAsYYYYMMDDHHNNSS(
          this.object.lastupdated
        );
      }

      // if (this.object.files !== null) {
      //   this.filesLoaded = true;
      // }
      this.receiptforms = [];
      this.receiptformsTemp.forEach((receiptform) => {
        receiptform.objects.forEach((object) => {
          if (object.id === this.objectid) {
            this.receiptforms.push(receiptform);
          }
        });
      });
      // this.pdfLoaded = true;
      // this.checklists = [];
      // this.checklistsTemp.forEach((checklist) => {
      //   // checklist.objects.forEach((object) => {
      //   checklist.pdfexports = [];
      //   this.checklistpdfs.forEach((cpdf) => {
      //     if (cpdf.checklistid === checklist.id) {
      //       checklist.pdfexports.push(cpdf);
      //     }
      //   });
      //   if (checklist.objectid === this.objectid) {
      //     console.log(checklist)
      //     this.checklists.push(checklist);
      //   }
      //   // });
      // });
      this.receiptforms.forEach((receiptform) => {
        receiptform.objectsstring = '';
        receiptform.objects.forEach((object) => {
          receiptform.objectsstring +=
            object.type?.name + ' - ' + object.name + '\n';
        });
        receiptform.objectsstring = receiptform.objectsstring.slice(0, -2);
      });
      this.isLoading = false;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  toggleEditObjecttype() {
    this.editObjecttype = !this.editObjecttype;
  }

  onSelectionChange(objecttype: Objecttype) {
    this.object.type = objecttype;
    this.updateObject();
    this.editObjecttype = false;
  }

  toggleArchived() {
    this.object.isarchived = !this.object.isarchived;
    this.updateObject();
  }

  deleteObjectConfirm(): void {
    const sheet = this.bottomSheet.open(ConfirmComponent, {
      backdropClass: 'blur-dialog-backdrop',
      data: {
        title: 'Er du sikker?',
        text: 'Slette objekt: ' + this.object.name,
        yes: {
          name: 'Ja',
          icon: 'delete',
        },
        no: {
          name: 'Nei',
          icon: 'close',
        },
      },
    });
    sheet
      .afterDismissed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((confirm) => {
        if (confirm) {
          this.objectservice
            .deleteObject(this.object)
            .then(() => {
              console.log('Object deleted...');
              this.router.navigateByUrl(
                'p/' + this.idservice.projectId() + '/o'
              );
              this.snackbarservice.openSnackBar('Objekt slettet...', '');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }

  toggleEditName() {
    this.editname = !this.editname;
    this.Form.patchValue({
      name: this.object.name,
    });
  }
  saveName(name: string) {
    this.object.name = name;
    this.updateObject();
    this.editname = false;
  }
  toggleEditDesc() {
    this.editdesc = !this.editdesc;
    this.FormDesc.patchValue({
      desc: this.object.desc,
    });
  }
  saveDesc(desc: string) {
    this.object.desc = desc;
    this.updateObject();
    this.editdesc = false;
  }
  toggleEditProfilenumber() {
    this.editprofilenumber = !this.editprofilenumber;
    this.FormProfilenumber.patchValue({
      profilenumber: this.object.profilenumber,
    });
  }
  saveProfilenumber(profilenumber: string) {
    this.object.profilenumber = profilenumber;
    this.updateObject();
    this.editprofilenumber = false;
  }
  toggleEditProcess() {
    this.editprocess = !this.editprocess;
    this.FormProcess.patchValue({
      process: this.object.process,
    });
  }
  saveProcess(process: string) {
    this.object.process = process;
    this.updateObject();
    this.editprocess = false;
  }

  toggleEditDrawing() {
    this.editdrawing = !this.editdrawing;
    this.FormDrawing.patchValue({
      drawing: this.object.drawing,
    });
  }
  saveDrawing(drawing: string) {
    this.object.drawing = drawing;
    this.updateObject();
    this.editdrawing = false;
  }

  toggleEditComment() {
    this.editcomment = !this.editcomment;
    this.FormComment.patchValue({
      comment: this.object.comment,
    });
  }
  saveComment(comment: string) {
    this.object.comment = comment;
    this.updateObject();
    this.editcomment = false;
  }

  toggleEditTags(index: number) {
    if (!this.edittags[index]) {
      this.clearEditTags();
      this.edittags[index] = true;
    } else {
      this.clearEditTags();
    }
    if (this.edittags[index]) {
      this.FormTags.patchValue({
        key: this.object.tags[index].key,
        value: this.object.tags[index].value,
      });
    }
  }
  saveTags(key: string, value: string, index: number) {
    if (key === '' && value === '') {
      this.object.tags.splice(index, 1);
    } else {
      this.object.tags[index] = { key, value };
    }
    this.updateObject();
    this.edittags[index] = false;
  }
  newTag() {
    let newindex = 0;
    if (this.object.tags) {
      newindex = this.object.tags.length;
    } else {
      this.object.tags = [];
    }
    this.object.tags[newindex] = { key: '', value: '' };
    this.updateObject();
    this.clearEditTags();
    this.edittags[newindex] = true;
    this.FormTags.patchValue({
      key: this.object.tags[newindex].key,
      value: this.object.tags[newindex].value,
    });
  }
  clearEditTags() {
    this.edittags.forEach((_, i, arr) => {
      arr[i] = false;
    });
  }

  updateObject() {
    const object = this.object;
    if (object.checklists) {
      object.checklists.pop();
    }
    this.objectservice.updateObject(object);
  }

  newReceiptformClick() {
    this.popupservice.openDialogNewReceiptForm(this.user, this.projectid, this.object);
  }

  copyFileLocation() {
    this.currentpathservice.copyFilePathToClipboard();
  }
}
