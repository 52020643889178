import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, QueryDocumentSnapshot} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import firebase from 'firebase/compat/app';

import {firstValueFrom, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import _ from 'underscore';
import {AventiObject} from '../models/AventiObject';
import {DbpathService} from './dbpath.service';
import {IdService} from './id.service';
import {SharedService} from './shared.service';
import {SnackbarService} from './snackbar.service';
import {DateService} from './date.service';
import {SortService} from './sort.service';
import {ChecklistService} from './checklist.service';
import FieldValue = firebase.firestore.FieldValue;

@Injectable()
export class ObjectService {

  object: AventiObject;

  constructor(
    private afs: AngularFirestore,
    private idservice: IdService,
    private dbpathservice: DbpathService,
    private sharedservice: SharedService,
    private snackbarservice: SnackbarService,
    private router: Router,
    private dateservice: DateService,
    private sortservice: SortService,
    private checklistservice: ChecklistService,
  ) {
    this.object = new AventiObject();
  }

  getObjectFiles(objectid: string) {
    const projectid = this.idservice.projectId();
    return this.afs
      .collection(
        this.dbpathservice.dbPathObjectFiles(objectid, projectid),
        (ref) =>
          ref
            .where('projectid', '==', projectid)
            .orderBy('name')
      )
      .valueChanges({idField: 'id'});
  }

  getObjectsByProjectId(projectid?: string): Observable<AventiObject[]> {
    if (!projectid) {
      projectid = this.idservice.projectId();
    }
    return this.afs
      .collection(this.dbpathservice.dbPathObjects(projectid), (ref) =>
        ref
          .where('projectid', '==', projectid)
          .orderBy('name')
      ).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((action) => {
            const id = action.payload.doc.id;
            const object = {...action.payload.doc.data() as AventiObject};
            object.editedBy = this.dateservice.getLatestEditedByFromArray(object.editedBy);
            const fromCache = action.payload.doc.metadata.fromCache;
            return {id, fromCache, ...object} as AventiObject;
          });
        }));
  }

  batchGetObjectsByProjectId(projectid?: string): Observable<AventiObject[]> {
    if (!projectid) {
      projectid = this.idservice.projectId();
    }

    const batchSize = 500;
    let lastDocument: QueryDocumentSnapshot<any> | null = null;
    const fetchedObjects: AventiObject[] = [];
    let actionsLength = batchSize + 1;

    return new Observable<AventiObject[]>((observer) => {
      const fetchData = (startAfter: QueryDocumentSnapshot<any> | null) => {
        const query = this.afs.collection(
          this.dbpathservice.dbPathObjects(projectid),
          (ref) =>
            ref
              .where('projectid', '==', projectid)
              .orderBy('name')
              .limit(batchSize)
              .startAfter(startAfter)
        );

        query.snapshotChanges().pipe(take(1)).subscribe(actions => {
          actionsLength = actions.length;
          actions.forEach((action) => {
            const id = action.payload.doc.id;
            const object = {...action.payload.doc.data() as AventiObject};
            object.editedBy = this.dateservice.getLatestEditedByFromArray(object.editedBy);
            const fromCache = action.payload.doc.metadata.fromCache;
            fetchedObjects.push({id, fromCache, ...object} as AventiObject);
            lastDocument = action.payload.doc;
          });

          observer.next(fetchedObjects);
          console.log(actionsLength);

          if (actions.length === 0 || actions.length < batchSize) {
            console.log('COMPLETE');
            observer.complete();
          } else {
            console.log(lastDocument?.id);
            fetchData(lastDocument);
          }
        });
      };
      fetchData(null);
    });
  }

  getObjectsByProjectIdPromise(projectid?: string, limit: number = 1000, iteration: number = 0): Promise<AventiObject[]> {
    if (!projectid) {
      projectid = this.idservice.projectId();
    }
    return firstValueFrom(this.afs
      .collection(this.dbpathservice.dbPathObjects(projectid), (ref) =>
          ref
            .where('projectid', '==', projectid)
            .orderBy('name')
            .startAfter(iteration * limit)
            .endAt(iteration * limit + limit)
        // .limit(limit)
      ).get()
      .pipe(
        map((actions) => {
          return actions.docs.map((action) => {
            const id = action.id;
            const object = {...action.data() as AventiObject};
            object.editedBy = this.dateservice.getLatestEditedByFromArray(object.editedBy);
            const fromCache = action.metadata.fromCache;
            return {id, fromCache, ...object} as AventiObject;
          });
        })));
  }

  async set(object: AventiObject, redirect: boolean = false, createDespiteContainingId: boolean = false) {
    object = _.omit(object, 'checklists');
    object = _.omit(object, 'files');
    object = _.omit(object, 'pdfexports');
    const projectid = object.projectid;
    const col = this.getObjectCollectionByProjectId(projectid);

    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        const timestamp = firebase.firestore.Timestamp.now();
        const usertimestamp = {
          user,
          timestamp,
        };
        if (!object.editedBy) {
          object.editedBy = [];
        }
        object.editedBy.push(usertimestamp);
        object.editedBy = this.sortservice.reduceArrayToMaxLength(object.editedBy, 'timestamp');

        object.lastupdated = timestamp;
        object.name = object.name.replace(/\r\n/g, '');
        console.log('Ready to set object');
        if (object.id && !createDespiteContainingId) {
          const ref = col.doc(object.id).ref;
          ref
            .update(object)
            .then((x) => {
              return x;
            })
            .catch((err) => {
              return err;
            });
        } else {
          const docRefObject = this.afs.firestore
            .collection(
              this.dbpathservice.dbPathObjects(projectid)
            )
            .doc();
          let item: AventiObject;
          object.id = docRefObject.id;
          item = object;
          Object.keys(item).forEach(
            (key) => item[key] === null && delete item[key]
          );
          console.log('Setting object', object);
          docRefObject
            .set(object)
            .then((o) => {
              console.log('object added', o);
            })
            .catch((err) => {
              return err;
            });
          this.sharedservice.objects
            .pipe(take(1))
            .subscribe((objects) => {
              if (objects !== null) {
                objects.push(object);
                this.sharedservice.objects.next(objects);
                if (redirect) {
                  this.router.navigateByUrl(
                    'p/' + object.projectid + '/o/' + object.id
                  );
                  this.sharedservice.isLoading.next([false, '', ['']]);
                } else {
                  this.snackbarservice.openSnackBar(
                    'Objekt opprettet: ' + object.name,
                    ''
                  );
                }
              }
            });
        }
      }
    });
  }

  updateObject(object: AventiObject) {
    const id = object.id;

    object = _.omit(object, 'checklists');
    object = _.omit(object, 'files');
    object = _.omit(object, 'pdfexports');
    this.sharedservice.user.pipe(take(1)).subscribe((u) => {
      if (u !== null) {
        const user = u[0];
        const timestamp = firebase.firestore.Timestamp.now(); // Timestamp.now();
        const usertimestamp = {
          user: {id: user.id},
          timestamp,
        };
        object.editedBy = [usertimestamp];

        object.lastupdated = timestamp;
        const path = this.dbpathservice.dbPathObject(id);
        this.afs.doc(path).update(object);
      }
    });
  }


// Function to update a field
  async updateField(id: string, field: string, data: any) {
    if (!id || !field) {
      throw new Error('Invalid parameters provided to updateField.');
    }

    const path = this.dbpathservice.dbPathObject(id);
    const updateObject = {
      [field]: data
    };

    try {
      await this.afs.doc(path).update(updateObject);
      console.log(`Field "${field}" updated successfully for document ID "${id}".`);
    } catch (error) {
      console.error('Error updating field:', error);
      throw error;
    }
  }

// Function to delete a field
  async deleteField(id: string, field: string) {
    if (!id || !field) {
      throw new Error('Invalid parameters provided to deleteField.');
    }

    const path = this.dbpathservice.dbPathObject(id);
    const updateObject = {
      [field]: FieldValue.delete()
    };

    try {
      await this.afs.doc(path).update(updateObject);
      console.log(`Field "${field}" deleted successfully for document ID "${id}".`);
    } catch (error) {
      console.error('Error deleting field:', error);
      throw error;
    }
  }

  async deleteObject(object: AventiObject) {
    let id: string;
    if (object) {
      id = object.id;
    } else {
      id = this.idservice.objectId();
    }
    if (object?.checklists) {
      for (const checklist of object.checklists) {
        await this.checklistservice.deleteChecklist(checklist, false);
      }
    }
    const path = this.dbpathservice.dbPathObject(id);
    await this.afs.doc(path).delete();
  }

  async setArray(objects: AventiObject[]) {
    const col = this.getObjectCollectionByProjectId();

    const projectid = this.idservice.projectId();
    const chunkSize = 400; // 480; // max 500
    const timestamp = firebase.firestore.Timestamp.now();
    const users = await firstValueFrom(this.sharedservice.user.pipe(take(1)));
    const user = users[0];
    const usertimestamp = {
      user,
      timestamp,
    };
    const online = await firstValueFrom(this.sharedservice.online.pipe(take(1)));

    for (let i = 0; i < objects.length; i += chunkSize) {
      const objectsChunk = objects.slice(i, i + chunkSize);
      // Get a new write batch
      const batch = this.afs.firestore.batch();
      // Update values in objects
      for (const object of objectsChunk) {
        if (!object.projectid) {
          object.projectid = projectid;
        }
        if (!object.editedBy) {
          object.editedBy = [];
        }
        object.editedBy.push(usertimestamp);
        object.editedBy = this.sortservice.reduceArrayToMaxLength(object.editedBy, 'timestamp');

        object.lastupdated = timestamp;
        object.name = object.name.toString().replace(/\r\n/g, '');
        // const itemOmitID = _.omit(item, 'id');
        if (object.id) {
          // console.log('update', object);
          const ref = col.doc(object.id).ref;
          // if (!_.isEmpty(_.compact(item))) {
          if (online) {
            batch.update(ref, object);
          } else {
            this.updateObject(object);
          }
        } else {
          if (online) {
            const id = this.afs.createId();
            const ref = col.doc(id).ref;
            let aventiObjectItem: AventiObject;
            aventiObjectItem = object;
            aventiObjectItem.id = id;

            batch.set(ref, aventiObjectItem);
          } else {
            await this.set(object);
          }
        }
      }
      // Commit the batch
      if (online) {
        console.log(`Skriver objekt ${i + 1} til ${i + chunkSize} til databasen`);
        this.sharedservice.isLoading.next([
          true,
          'Vennligst vent...',
          [
            `Importerer ${objects.length} objekter...`
            // `Skriver objekt ${i + 1} til ${i + chunkSize} til databasen`
          ],
        ]);
        this.snackbarservice.openSnackBar(`Skriver objekt ${i + 1} til ${i + chunkSize} til databasen`, null);
        await batch.commit();
        this.sharedservice.isLoading.next([false, '', ['']]);
      }
    }
    this.sharedservice.isLoading.next([false, '', ['']]);

    return 'success';
  }


  private getObjectCollectionByProjectId(projectid?: string): AngularFirestoreCollection<AventiObject> {
    if (!projectid) {
      projectid = this.idservice.projectId();
    }
    return this.afs.collection('projects/' + projectid + '/objects', (ref) => {
      return ref;
    });
  }
}
